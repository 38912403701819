// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* This is required to properly render the bubble text (which seems linke a browser bug) */
.text-editor__wrapper div.ProseMirror .mention[contenteditable=false][data-v-297bb5fa] * {
	-webkit-user-modify: read-only !important;
}
`, "",{"version":3,"sources":["webpack://./src/extensions/Mention.vue"],"names":[],"mappings":";AA8CA,0FAAA;AACA;CACA,yCAAA;AACA","sourcesContent":["<template>\n\t<NodeViewWrapper as=\"span\" class=\"mention\" contenteditable=\"false\">\n\t\t<NcUserBubble :user=\"node.attrs.id\"\n\t\t\t:display-name=\"username\"\n\t\t\t:primary=\"isCurrentUser\"\n\t\t\tclass=\"mention-user-bubble\">\n\t\t\t@{{ username }}\n\t\t</NcUserBubble>\n\t</NodeViewWrapper>\n</template>\n\n<script>\nimport { NcUserBubble } from '@nextcloud/vue'\nimport { NodeViewWrapper } from '@tiptap/vue-2'\nimport { getCurrentUser } from '@nextcloud/auth'\n\nexport default {\n\tname: 'Mention',\n\tcomponents: {\n\t\tNcUserBubble,\n\t\tNodeViewWrapper,\n\t},\n\tprops: {\n\t\tupdateAttributes: {\n\t\t\ttype: Function,\n\t\t\trequired: true,\n\t\t},\n\n\t\tnode: {\n\t\t\ttype: Object,\n\t\t\trequired: true,\n\t\t},\n\t},\n\tdata() {\n\t\treturn {\n\t\t\tusername: this.node.attrs.label,\n\t\t}\n\t},\n\tcomputed: {\n\t\tisCurrentUser() {\n\t\t\treturn this.node.attrs.id === getCurrentUser()?.uid\n\t\t},\n\t},\n}\n</script>\n<style scoped>\n/* This is required to properly render the bubble text (which seems linke a browser bug) */\n.text-editor__wrapper div.ProseMirror .mention[contenteditable=false] :deep(*) {\n\t-webkit-user-modify: read-only !important;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
