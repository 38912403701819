import { render, staticRenderFns } from "./BaseReader.vue?vue&type=template&id=151c6c0d&scoped=true"
import script from "./BaseReader.vue?vue&type=script&lang=js"
export * from "./BaseReader.vue?vue&type=script&lang=js"
import style0 from "./BaseReader.vue?vue&type=style&index=0&id=151c6c0d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "151c6c0d",
  null
  
)

export default component.exports